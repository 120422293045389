<template>
  <div class="container" :style="{ paddingTop: `${headerHeight}px` }">
    <div v-if="!participate && isWithinTimeRange" class="interactivity-box">
      <h1>Participe da enquete!</h1>
      <p>{{ interactivity.title }}</p>
      <div class="options-box">
        <button
          v-for="(option, index) in options"
          :key="index"
          @click="setParticipate(option)"
        >
          {{ option }}
        </button>
      </div>
    </div>
    <div v-else-if="participate" class="participate-box">
      <h1>Obrigado por participar!</h1>
    </div>
    <div v-else-if="hasInteractions" class="participate-box">
      <h1>Não há interações disponíveis<br>no momento.</h1>
    </div>
  </div>
</template>

<script>
import { getInteractivity, postInteractivityAnswer } from "@/services/api";

export default {
  name: "InteractivityPage",
  props: {
    headerHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      participate: false,
      interactivity: null,
      options: [],
      isWithinTimeRange: false,
      timerInterval: null,
      hasInteractions: false,
    };
  },
  async created() {
    await this.fetchInteractivity();
    this.timerInterval = setInterval(this.checkTimeRange, 60000); // Check every minute
  },
  beforeUnmount() {
    clearInterval(this.timerInterval); // Clear the timer interval to prevent memory leaks
  },
  methods: {
    async fetchInteractivity() {
      const response = await getInteractivity(localStorage.getItem("id"));
      if (response) {
        this.interactivity = response;
        if (this.interactivity.participate) {
          this.participate = true;
        }
        this.options = this.getOptions(response);
        this.checkTimeRange(); // Initial check
      } else {
        this.hasInteractions = true;
      }
    },
    async setParticipate(option) {
      this.participate = true;
      await postInteractivityAnswer(
        localStorage.getItem("id"),
        this.interactivity.id,
        option
      );
    },
    getOptions(interactivity) {
      const options = [];
      for (let i = 1; i <= 6; i++) {
        const option = interactivity[`option${i}`];
        if (option) options.push(option);
      }
      return options;
    },
    checkTimeRange() {
      if (!this.interactivity) return; // Exit if interactivity data is not available
      const createdDate = new Date(this.interactivity.createdAt);
      const currentTime = new Date();
      const expirationTime = new Date(
        createdDate.getTime() + this.interactivity.time * 60000
      ); // Convert minutes to milliseconds
      this.isWithinTimeRange = currentTime < expirationTime;
    },
  },
};
</script>

<style scoped src="@/assets/css/interactivity.css"></style>
