<template>
  <footer>
    <img src="@/assets/images/logo-footer.png" alt="Imagem 1" />
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
};
</script>
