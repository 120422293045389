<template>
  <div class="container" :style="{ paddingTop: `${headerHeight}px` }">
    <div class="booking-box" v-for="booking in bookings" :key="booking.id">
      <div class="grey-box">
        <h1>{{ booking.name }}</h1>
        <template v-if="booking.full && !booking.registered">
          <p>A atividade está cheia.</p>
        </template>
        <template v-if="booking.registered">
          <p>Você está registrado na atividade.</p>
          <button class="btn-primary" @click="cancelReservation(booking.id)">Cancelar Reserva</button>
        </template>
        <template v-else-if="!booking.full && !booking.registered">
          <button class="btn-primary" v-if="!buttonHidden" @click="reserve(booking.id)">Reservar</button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { getBookings, reserveBooking, removeBooking } from '@/services/api';

export default {
  name: "BookingPage",
  props: {
    headerHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      buttonHidden: true,
      bookings: []
    };
  },
  created() {
    this.fetchBookings();
  },
  methods: {
    async fetchBookings() {
      try {
        const userId = localStorage.getItem('id');
        const response = await getBookings(userId);
        this.bookings = response;
      } catch (error) {
        console.error('Error fetching bookings:', error);
      } finally {
        this.loading = false;
        this.buttonHidden = false;
      }
    },
    async reserve(bookingId) {
      try {
        const userId = localStorage.getItem('id');
        await reserveBooking(userId, bookingId);
        await this.fetchBookings();
        const bookingIndex = this.bookings.findIndex(booking => booking.id === bookingId);
        if (bookingIndex !== -1) {
          this.bookings[bookingIndex].registered = true;
        }
      } catch (error) {
        console.error('Error reserving booking:', error);
      }
    },
    async cancelReservation(bookingId) {
      try {
        const userId = localStorage.getItem('id');
        await removeBooking(userId, bookingId);
        await this.fetchBookings();
        const bookingIndex = this.bookings.findIndex(booking => booking.id === bookingId);
        if (bookingIndex !== -1) {
          this.bookings[bookingIndex].registered = false;
        }
      } catch (error) {
        console.error('Error canceling reservation:', error);
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/booking.css"></style>
