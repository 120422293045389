<template>
  <div class="container" :style="{ paddingTop: `${headerHeight}px` }">
    <div class="schedule">
      <div class="tabs">
        <div
          class="tab"
          v-for="(date, index) in dates"
          :key="index"
          @click="selectTab(index)"
          :class="{ 'active': activeTabIndex === index }"
        >
          {{ date }}
        </div>
      </div>
      <div class="content">
        <div v-show="activeTabIndex === index" v-for="(dateContent, index) in dateContents" :key="index">
          <div v-for="item in dateContent" :key="item.id" class="schedule-item">
            <p>{{ item.content }}</p>
            <p>{{ item.hour }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSchedule } from "@/services/api";

export default {
  name: "SchedulePage",
  props: {
    headerHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dates: [],
      dateContents: [],
      activeTabIndex: 0,
    };
  },
  async created() {
    await this.fetchSchedule();
  },
  methods: {
    async fetchSchedule() {
      const response = await getSchedule();
      if (response) {
        this.dates = Object.keys(response);
        this.dateContents = Object.values(response);
      }
    },
    selectTab(index) {
      this.activeTabIndex = index;
    },
  },
};
</script>

<style scoped src="@/assets/css/schedule.css"></style>
