<template>
  <div class="register-container">
    <HeaderComponent></HeaderComponent>

    <main>
      <div class="welcomeText">
        <h2>Olá, {{ getFirstName(userData.nome) }}</h2>
        <p>
          Agora precisamos que você atualize<br />seus dados para concluir o
          cadastro
        </p>
      </div>
      <form class="registerForm w-100" @submit.prevent="submitForm">
        <div class="pw-50">
          <input
            type="text"
            id="nome"
            class="w-100 mb-10"
            v-model="formData.nome"
            placeholder="Nome"
            required
          />
          <input
            type="text"
            id="email"
            class="w-100 mb-10"
            v-model="formData.email"
            placeholder="E-mail"
            required
          />
          <input
            type="text"
            id="telefone"
            class="w-100"
            v-model="formData.telefone"
            placeholder="Telefone"
            required
          />

          <p class="mb-10 mt-10">Necessidades especiais:</p>
          <div class="radioBox">
            <label class="checkbox-label label-custom">
              <input
                type="radio"
                id="necessidadeFisicaRadioSim"
                class="w-auto"
                name="necessidadeFisicaRadio"
                value="Sim"
                v-model="formData.necessidadeFisicaRadio"
                @change="handleNecessidadeEspeciaisChange('Sim')"
              />
              Sim
            </label>
            <label class="checkbox-label label-custom">
              <input
                type="radio"
                id="necessidadeFisicaRadioNao"
                class="w-auto"
                name="necessidadeFisicaRadio"
                value="Não"
                v-model="formData.necessidadeFisicaRadio"
                @change="handleNecessidadeEspeciaisChange('Não')"
              />
              Não
            </label>
          </div>
          <div v-if="nessecidadesEspeciais">
            <label class="checkbox-label">
              <input
                type="checkbox"
                id="necessidadeFisica"
                class="w-auto"
                v-model="formData.necessidadeFisicaCheckbox"
                :checked="userData.necessidade_fisica !== ''"
              />
              Física
            </label>
            <input
              v-if="formData.necessidadeFisicaCheckbox"
              type="text"
              id="necessidadeFisicaText"
              class="w-100"
              placeholder="Necessidade Física"
              v-model="formData.necessidade_fisica"
            />

            <label class="checkbox-label">
              <input
                type="checkbox"
                id="necessidadeAlergia"
                class="w-auto"
                v-model="formData.necessidadeAlergiaCheckbox"
                :checked="userData.necessidade_alergia !== ''"
              />
              Alergia
            </label>
            <input
              v-if="formData.necessidadeAlergiaCheckbox"
              type="text"
              class="w-100"
              id="necessidadeAlergiaText"
              placeholder="Alergias"
              v-model="formData.necessidade_alergia"
            />

            <label class="checkbox-label">
              <input
                type="checkbox"
                id="necessidadeAlimentar"
                class="w-auto"
                v-model="formData.necessidadeAlimentarCheckbox"
                :checked="userData.necessidade_alimentar !== ''"
              />
              Alimentar
            </label>
            <input
              v-if="formData.necessidadeAlimentarCheckbox"
              type="text"
              id="necessidadeAlimentarText"
              class="w-100"
              placeholder="Alimentar"
              v-model="formData.necessidade_alimentar"
            />
          </div>

          <select
            id="transfer"
            class="mb-10"
            v-model="formData.transfer"
            required
          >
            <option value="" selected hidden style="color: #ccc">
              Transfer Aeroporto &lt;&gt; Hotel
            </option>
            <option value="Nao precisa">Não Precisa</option>
            <option value="Somente Ida">Somente Ida</option>
            <option value="Somente Volta">Somente Volta</option>
            <option value="Ida e Volta">Ida e Volta</option>
          </select>
        </div>

        <button class="btn-primary mt-10 mb-10" type="submit">Avançar</button>
      </form>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import HeaderComponent from "../Header/HeaderComponent.vue";

export default {
  components: {
    HeaderComponent,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
    }),
  },
  data() {
    return {
      formData: {
        id: 0,
        nome: "",
        email: "",
        telefone: "",
        necessidadeFisicaCheckbox: false,
        necessidadeFisica: "",
        necessidadeAlergiaCheckbox: false,
        necessidadeAlergia: "",
        necessidadeAlimentarCheckbox: false,
        necessidadeAlimentar: "",
        transfer: "",
        necessidadeFisicaRadio: "Não",
      },
      nessecidadesEspeciais: false,
    };
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (this.userData) {
      this.formData.id = this.userData.id;
      this.formData.nome = this.userData.nome || "";
      this.formData.email = this.userData.email || "";
      this.formData.telefone = this.userData.telefone || "";
      this.formData.necessidadeFisicaCheckbox =
        !!this.userData.necessidade_fisica;
      this.formData.necessidadeAlergiaCheckbox =
        !!this.userData.necessidade_alergia;
      this.formData.necessidadeAlimentarCheckbox =
        !!this.userData.necessidade_alimentar;
      this.formData.necessidade_fisica = this.userData.necessidade_fisica || "";
      this.formData.necessidade_alergia =
        this.userData.necessidade_alergia || "";
      this.formData.necessidade_alimentar =
        this.userData.necessidade_alimentar || "";
      this.formData.transfer = this.userData.transfer || "";

      if (
        this.formData.necessidade_fisica !== "" ||
        this.formData.necessidade_alimentar !== "" ||
        this.formData.necessidade_alergia !== ""
      ) {
        this.nessecidadesEspeciais = true;
        this.formData.necessidadeFisicaRadio = "Sim";
      }
    }
  },
  methods: {
    ...mapActions(["updateUserData"]),
    async submitForm() {
      this.updateUserData(this.formData);
      this.$emit("next");
    },
    getFirstName(fullName) {
      return fullName.split(" ")[0];
    },
    handleNecessidadeEspeciaisChange(value) {
      this.nessecidadesEspeciais = value === "Sim";
    },
  },
};
</script>

<style scoped src="@/assets/css/register.css"></style>
<style scoped>
.radioBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.label-custom {
  margin-top: 0 !important;
}
</style>
