<template>
  <div>
    <header>
      <img src="@/assets/images/logo.png" alt="Logo" />
    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderComponent",
};
</script>
