import { createApp } from "vue";
import App from "@/App.vue";
import LoginForm from "@/components/Login/LoginForm.vue";
import HomePage from "@/components/HomePage/HomePage.vue";
import Admin from "@/components/Admin/AdminComponent.vue";
import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/store";
import "@/assets/css/global.css";
import "@mdi/font/css/materialdesignicons.min.css";
import VueJwtDecode from 'vue-jwt-decode'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/", component: LoginForm },
    { path: "/home", component: HomePage, meta: { requiresAuth: true } },
    { path: "/admin", component: Admin, meta: { requiresAuth: true } },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem('token');
    if (!token) {
      next('/');
    } else {
      const decodedToken = VueJwtDecode.decode(token);
      if (decodedToken?.userId) {
        next();
      } else {
        next('/');
      }
    }
  } else {
    next();
  }
});

const app = createApp(App);
app.use(router);
app.use(store);
app.mount("#app");
