<template>
  <div class="container-fluid">
    <div class="container">
      <h1>Interatividade</h1>
      <form @submit.prevent="createInteractivity">
        <div>
          <label for="title">Título:</label>
          <input
            type="text"
            id="title"
            v-model="interactivityData.title"
            required
          />
        </div>
        <div>
          <label for="time">Tempo (minutos):</label>
          <input
            type="number"
            id="time"
            v-model.number="interactivityData.time"
            required
          />
        </div>
        <div>
          <label for="option1">Opção 1:</label>
          <input
            type="text"
            id="option1"
            v-model="interactivityData.option1"
            required
          />
        </div>
        <div>
          <label for="option2">Opção 2:</label>
          <input
            type="text"
            id="option2"
            v-model="interactivityData.option2"
            required
          />
        </div>
        <div>
          <label for="option3">Opção 3:</label>
          <input type="text" id="option3" v-model="interactivityData.option3" />
        </div>
        <div>
          <label for="option4">Opção 4:</label>
          <input type="text" id="option4" v-model="interactivityData.option4" />
        </div>
        <div>
          <label for="option5">Opção 5:</label>
          <input type="text" id="option5" v-model="interactivityData.option5" />
        </div>
        <div>
          <label for="option6">Opção 6:</label>
          <input type="text" id="option6" v-model="interactivityData.option6" />
        </div>
        <button class="btn-primary" type="submit">Criar</button>
      </form>
    </div>
    <div class="container">
      <h1>Novidades</h1>
      <form @submit.prevent="createNews">
        <div>
          <label for="title">Arquivo:</label>
          <input type="file" id="title" @change="handleUpload" required />
        </div>
        <div>
          <label for="content">Conteúdo:</label>
          <input type="text" id="content" v-model="newsData.content" required />
        </div>
        <button class="btn-primary" type="submit">Criar</button>
      </form>
      <h1>Galeria</h1>
      <form @submit.prevent="createGallery">
        <div>
          <label for="title">Arquivo:</label>
          <input
            type="file"
            id="title"
            @change="handleGalleryUpload"
            required
          />
        </div>
        <div>
          <label for="content">Conteúdo:</label>
          <input
            type="text"
            id="content"
            v-model="galleryData.content"
            required
          />
        </div>
        <div>
          <label for="content">Data:</label>
          <input type="text" id="data" v-model="galleryData.data" required />
        </div>
        <button class="btn-primary" type="submit">Criar</button>
      </form>
      <h1>Exportar usuários</h1>
      <form @submit.prevent="exportUsers">
        <button class="btn-primary" type="submit">Exportar</button>
      </form>
    </div>
  </div>
</template>

<script>
import {
  createInteractivity,
  createNews,
  exportUsers,
  createGallery,
} from "@/services/api";

export default {
  name: "AdminComponent",
  data() {
    return {
      interactivityData: {
        title: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        option5: "",
        option6: "",
        time: 0,
      },
      newsData: {
        file: "",
        content: "",
      },
      galleryData: {
        file: "",
        content: "",
        data: "",
      },
    };
  },
  async created() {
    console.log();
    if (localStorage.getItem("permission") != 1) {
      this.$router.push("/");
    }
  },
  methods: {
    async createInteractivity() {
      try {
        await createInteractivity(this.interactivityData);
        this.clearForm(this.interactivityData);
      } catch (error) {
        console.error("Error creating interactivity:", error);
      }
    },
    async createNews() {
      try {
        await createNews(this.newsData);
        this.clearForm(this.newsData);
      } catch (error) {
        console.error("Error creating interactivity:", error);
      }
    },
    async createGallery() {
      try {
        await createGallery(this.galleryData);
        this.clearForm(this.galleryData);
      } catch (error) {
        console.error("Error creating Gallery:", error);
      }
    },
    async exportUsers() {
      const response = await exportUsers();
      const arrayBuffer = await response.arrayBuffer();

      const blob = new Blob([arrayBuffer], { type: "text/csv" });

      const downloadLink = document.createElement("a");
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.setAttribute("download", "users.csv");
      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
    },
    clearForm(form) {
      for (const key in form) {
        form[key] = "";
      }
    },
    handleUpload(event) {
      const file = event.target.files[0];
      if (file != undefined) {
        this.newsData.file = file;
      }
    },
    handleGalleryUpload(event) {
      const file = event.target.files[0];
      if (file != undefined) {
        this.galleryData.file = file;
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/admin.css"></style>
