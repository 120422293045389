<template>
  <div class="modal" v-if="show">
    <div class="modal-content">
      <svg-icon type="mdi" :path="mdiAlertOutline" :size="50"></svg-icon>
      <h3>Número de CPF não encontrado!</h3>
      <p>
        Não foi possível encontrar seus dados no cadastro ou o CPF foi digitado
        incorretamente.
      </p>
      <div class="modal-footer">
        <button @click="close" class="btn-seconday">Fechar</button>
        <button class="btn-primary">
          <svg-icon type="mdi" :path="mdiWhatsapp" :size="24"></svg-icon> Falar
          com o suporte
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiAlertOutline, mdiWhatsapp } from "@mdi/js";

export default {
  components: {
    SvgIcon,
  },
  data() {
    return {
      mdiAlertOutline: mdiAlertOutline,
      mdiWhatsapp: mdiWhatsapp,
    };
  },
  name: "ModalComponent",
  props: {
    show: Boolean,
    message: String,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped src="@/assets/css/modal.css"></style>
