<template>
  <div class="container">
    <main>
      <div>
        <header>
          <img src="@/assets/images/logo.png" alt="Logo" />
        </header>
        <p>
          Essa plataforma foi otimizada para visualização<br />
          em dispositivos móveis, escaneie o QRCode<br />
          abaixo usando seu celular para acessar.
        </p>
        <img class="qrcode" src="@/assets/images/qrcode.png" alt="qrcode" />
      </div>
      <footer>
        <img src="@/assets/images/logo-footer.png" alt="Imagem 1" />
      </footer>
    </main>
  </div>
</template>

<script>
export default {
  name: "DesktopComponent",
};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

p {
  color: #fff;
  margin: 0;
}

main {
  width: 800px;
  height: 100vh;
  align-items: center;
  justify-content: space-evenly;
}

footer img {
  width: 30%;
}

header img {
  width: 60%;
}

footer {
  margin-bottom: 0;
}

.qrcode {
  width: 200px;
  margin-top: 40px;
}
</style>
