import axios from "axios";

const API_BASE_URL = process.env.VUE_APP_BACKEND_URL;

export const checkCpf = async (cpf) => {
  const response = await axios.get(`${API_BASE_URL}/users/check-cpf`, {
    params: {
      cpf: cpf,
    },
  });
  return response.data;
};

export const forgetPassword = async (cpf) => {
  const response = await axios.get(`${API_BASE_URL}/users/forget`, {
    params: {
      cpf: cpf,
    },
  });
  return response.data;
};

export const getUser = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/users`, {
    params: {
      id: id,
    },
  });
  return response.data;
};

export const login = async (cpf, password) => {
  const response = await axios.post(`${API_BASE_URL}/users/login`, {
    cpf: cpf,
    password: password,
  });
  return response.data;
};

export const cadastrarUsuario = async (userData, imagem) => {
  const formData = new FormData();
  formData.append("id", userData.id);
  formData.append("nome", userData.nome);
  formData.append("telefone", userData.telefone);
  formData.append("necessidade_fisica", userData.necessidade_fisica);
  formData.append("necessidade_alergia", userData.necessidade_alergia);
  formData.append("necessidade_alimentar", userData.necessidade_alimentar);
  formData.append("transfer", userData.transfer);
  formData.append("file", imagem);

  const response = await axios.post(
    `${API_BASE_URL}/users/register`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  return response.data;
};

export const getNews = async () => {
  const response = await axios.get(`${API_BASE_URL}/news`);
  return response.data;
};

export const getInteractivity = async (id) => {
  const response = await axios.get(`${API_BASE_URL}/interactivity`, {
    params: {
      id: id,
    },
  });
  return response.data;
};

export const postInteractivityAnswer = async (
  userId,
  interactivityId,
  response
) => {
  await axios.post(`${API_BASE_URL}/interactivity/answer`, {
    userId: userId,
    interactivityId: interactivityId,
    response: response,
  });
};

export const getSchedule = async () => {
  const response = await axios.get(`${API_BASE_URL}/schedule`);
  return response.data;
};

export const getLogistics = async (cpf) => {
  const response = await axios.get(`${API_BASE_URL}/logistic`, {
    params: {
      cpf: cpf,
    },
  });
  return response.data;
};

export const createInteractivity = async (formData) => {
  await axios.post(`${API_BASE_URL}/interactivity/create`, {
    title: formData.title,
    option1: formData.option1,
    option2: formData.option2,
    option3: formData.option3,
    option4: formData.option4,
    option5: formData.option5,
    option6: formData.option6,
    time: formData.time,
  });
};

export const getBookings = async (userId) => {
  const response = await axios.get(`${API_BASE_URL}/booking`, {
    params: {
      id: userId,
    },
  });
  return response.data;
};

export const reserveBooking = async (userId, bookingId) => {
  await axios.post(`${API_BASE_URL}/booking/reserve`, {
    userId: userId,
    bookingId: bookingId,
  });
};

export const removeBooking = async (userId, bookingId) => {
  await axios.post(`${API_BASE_URL}/booking/remove`, {
    userId: userId,
    bookingId: bookingId,
  });
};

export const createNews = async (newsData) => {
  const formData = new FormData();
  formData.append("content", newsData.content);
  formData.append("file", newsData.file);

  const response = await axios.post(`${API_BASE_URL}/news/create`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export const exportUsers = async () => {
  const response = await axios.get(`${API_BASE_URL}/users/export`, {
    responseType: "blob",
    headers: {
      "Content-Type": "text/csv; charset=utf-8",
    },
  });
  return response.data;
};

export const getGallery = async () => {
  const response = await axios.get(`${API_BASE_URL}/gallery`);
  return response.data;
};

export const createGallery = async (galleryData) => {
  const formData = new FormData();
  formData.append("data", galleryData.data);
  formData.append("content", galleryData.content);
  formData.append("file", galleryData.file);

  const response = await axios.post(`${API_BASE_URL}/gallery/create`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
