<template>
  <div class="modal" v-if="show">
    <div class="modal-content">
      <svg-icon type="mdi" :path="mdiAlertOutline" :size="50"></svg-icon>
      <p>
        Um email com sua senha foi enviado para o seu
        endereço de email cadastrado.
      </p>
      <div class="modal-footer">
        <button @click="close" class="btn-seconday">Fechar</button>
      </div>
    </div>
  </div>
</template>

<script>
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiAlertOutline } from "@mdi/js";

export default {
  components: {
    SvgIcon,
  },
  data() {
    return {
      mdiAlertOutline: mdiAlertOutline,
    };
  },
  props: {
    show: Boolean,
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped src="@/assets/css/modal.css"></style>
