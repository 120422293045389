import { createStore } from 'vuex';

export default createStore({
  state: {
    userData: null,
    userForm: null,
    registerRes: null
  },
  mutations: {
    setUserData(state, userData) {
      state.userData = userData;
    },
    setUserForm(state, userForm) {
      state.userForm = userForm;
    },
    setRegisterRes(state, registerRes) {
      state.registerRes = registerRes;
    },
  },
  actions: {
    updateUserData({ commit }, userData) {
      commit('setUserData', userData);
    },
    updateUserForm({ commit }, userForm) {
      commit('setUserForm', userForm);
    },
    updateRegisterRes({commit}, registerRes) {
      commit('setRegisterRes', registerRes);
    }
  }
});
