<template>
  <div class="container">
    <HeaderHomePage
      ref="headerRef"
      @iconClick="changeContentComponent"
    ></HeaderHomePage>
    <div class="content">
      <component
        :is="currentContentComponent"
        :style="{ marginTop: headerHeight + 10 + 'px' }"
      ></component>
    </div>
  </div>
</template>

<script>
import { getUser } from "@/services/api";
import { mapActions } from "vuex";
import HeaderHomePage from "../Header/HeaderHomePage.vue";
import NewsPage from "./NewsPage.vue";
import QrCodePage from "./QrCodePage.vue";
import SchedulePage from "./SchedulePage.vue";
import LogisticPage from "./LogisticPage.vue";
import GalleryPage from "./GalleryPage.vue";
import InteractivityPage from "./InteractivityPage.vue";
import BookingPage from "./BookingPage.vue";

export default {
  components: {
    HeaderHomePage,
    NewsPage,
    QrCodePage,
    SchedulePage,
    LogisticPage,
    GalleryPage,
    InteractivityPage,
    BookingPage,
  },
  name: "HomePage",
  data() {
    return {
      currentContentComponent: "NewsPage",
      headerHeight: 0,
    };
  },
  async created() {
    const response = await getUser(localStorage.getItem("id"));
    if (response != null) {
      this.updateUserData(response);
      localStorage.setItem("token", response.token);
      localStorage.setItem("cpf", response.cpf);
    }
  },
  mounted() {
    this.setHeaderHeight();
    window.addEventListener("resize", this.setHeaderHeight);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.setHeaderHeight);
  },
  methods: {
    ...mapActions(["updateUserData"]),
    changeContentComponent(componentName) {
      this.currentContentComponent = componentName;
    },
    setHeaderHeight() {
      this.headerHeight = this.$refs.headerRef.$el.clientHeight;
    },
  },
};
</script>

<style scoped>
main {
  width: 80%;
}

h2 {
  color: #fff;
}

.w-80 {
  width: 80%;
}
</style>
<style scoped src="@/assets/css/home.css"></style>
