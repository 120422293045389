<template>
  <div class="register-container">
    <div class="floating-icon" @click="prevStep">
      <svg-icon
        type="mdi"
        :path="mdiArrowLeftBoldBox"
        :size="50"
        color="#f88c22"
      ></svg-icon>
    </div>
    <HeaderComponent></HeaderComponent>
    <main>
      <form @submit.prevent="submitPhotoForm">
        <h4>Sua participação começa aqui!</h4>
        <label for="file-upload" class="custom-file-upload">
          <div
            class="upload-box"
            :style="{ backgroundImage: `url(${uploadedImage})` }"
          >
            <div class="selfie-box" v-if="!uploadedImage">
              <span class="upload-text">Hora da selfie!</span>
              <svg-icon
                type="mdi"
                :path="mdiCameraOutline"
                :size="50"
                color="#f88c22"
              ></svg-icon>
              <span class="upload-text">Faça o upload da<br />sua foto!</span>
            </div>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              @change="handleImageUpload"
            />
          </div>
        </label>
        <div>
          <label class="checkbox-label">
            <input
              type="checkbox"
              id="authorize"
              class="w-auto"
              v-model="authorize"
              :checked="this.image !== null"
            />
            Autorizo o uso da minha imagem<br />
            durante o evento.
          </label>
        </div>
        <button
          class="btn-primary"
          @click="submitFullRegister"
          :disabled="isButtonClicked"
        >
          Confirmar Presença
        </button>
      </form>
    </main>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { cadastrarUsuario } from "@/services/api";
import HeaderComponent from "../Header/HeaderComponent.vue";
import SvgIcon from "@jamescoyle/vue-icon";
import { mdiCameraOutline, mdiArrowLeftBoldBox } from "@mdi/js";

export default {
  components: {
    HeaderComponent,
    SvgIcon,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
    }),
  },
  data() {
    return {
      uploadedImage: null,
      image: null,
      mdiCameraOutline: mdiCameraOutline,
      mdiArrowLeftBoldBox: mdiArrowLeftBoldBox,
      isButtonClicked: false,
    };
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  methods: {
    ...mapActions(["updateRegisterRes"]),
    handleImageUpload(event) {
      const file = event.target.files[0];
      if (file != undefined) {
        this.image = file;
        const reader = new FileReader();

        reader.onload = (e) => {
          this.uploadedImage = e.target.result;
        };

        reader.readAsDataURL(file);
      }
    },
    submitPhotoForm() {
      this.showImageUpload = true;
    },
    submitFullRegister() {
      this.isButtonClicked = true;
      cadastrarUsuario(this.userData, this.image)
        .then((res) => {
          this.updateRegisterRes(res);
          this.$emit("next");
        })
        .catch((error) => {
          console.error("Erro ao cadastrar usuário:", error);
          this.isButtonClicked = false;
        });
    },
    prevStep() {
      this.$emit("prev");
    },
  },
};
</script>

<style scoped>
img {
  width: 100px;
}
</style>

<style scoped>
.upload-box {
  border: 2px solid #f88c22;
  border-radius: 10px;
  background-color: transparent;
  text-align: center;
  width: 200px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.selfie-box {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.upload-text {
  margin-bottom: 10px;
  color: #f88c22;
}

.custom-file-upload {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "GT America Regular", sans-serif;
}

.image-container img {
  width: 100px;
}

#file-upload {
  visibility: hidden;
}

h4 {
  color: #fff;
}

.energia-significa {
  background-image: url("@/assets/images/note.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 117px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.inputEnergia {
  border: 0;
  font-size: 12px;
  text-align: center;
  color: #0a2923;
}

.inputEnergia::placeholder {
  color: #0a29239b;
}

.floating-icon {
  position: absolute;
  left: 10px;
  top: 10px;
}

label {
  font-family: "GT America Light", sans-serif;
  color: #fff;
  margin-top: 20px;
  gap: 5px;
  align-items: flex-start;
}
</style>

<style scoped src="@/assets/css/register.css"></style>
