<template>
  <div class="container" :style="{ paddingTop: `${headerHeight}px` }">
    <div class="qrCode-box">
      <div class="grey-box">
        <p>Use esse QR-Code para fazer o seu check-in no evento.</p>
        <img :src="userData ? userData : ''" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "QrCodePage",
  props: {
    headerHeight: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData.qrCode,
    }),
  },
};
</script>

<style scoped src="@/assets/css/qrCode.css"></style>
