<template>
  <div class="container" :style="{ paddingTop: `${headerHeight}px` }">
    <div class="logistic">
      <div class="tabs">
        <div
          class="tab"
          v-for="(date, index) in dates"
          :key="index"
          @click="selectTab(index)"
          :class="{ active: activeTabIndex === index }"
        >
          {{ date }}
        </div>
      </div>
      <div class="content">
        <div
          v-show="activeTabIndex === index"
          v-for="(dateContent, index) in dates"
          :key="index"
        >
          <div v-if="activeTabIndex === 0">
            <div v-if="!isObjectEmpty(aeroporto)">
              <h6>Cartão de embarque</h6>
              <div class="aeroporto-box pb-30">
                <h3>Vôo de Ida</h3>
                <p>{{ aeroporto.aeroporto_ida }}</p>
                <div>
                  <h5>Horário de embarque:</h5>
                  <p>{{ aeroporto.data_embarque_ida }}</p>
                  <p v-if="aeroporto.data_embarque_ida_con">{{ aeroporto.data_embarque_ida_con }}</p>
                  <p v-if="aeroporto.data_embarque_ida_con2">{{ aeroporto.data_embarque_ida_con2 }}</p>
                </div>
                <a
                  class="btn-primary"
                  v-if="
                    aeroporto.ticket_ida !== null && aeroporto.ticket_ida !== ''
                  "
                  :href="aeroporto.ticket_ida"
                  >Baixar e-ticket</a
                >
              </div>
              <div class="aeroporto-box">
                <h3>Vôo de volta</h3>
                <p>{{ aeroporto.aeroporto_volta }}</p>
                <div>
                  <h5>Horário de embarque:</h5>
                  <p>{{ aeroporto.data_embarque_volta }}</p>
                  <p v-if="aeroporto.data_embarque_volta_con">{{ aeroporto.data_embarque_volta_con }}</p>
                  <p v-if="aeroporto.data_embarque_volta_con2">{{ aeroporto.data_embarque_volta_con2 }}</p>
                </div>
                <a
                  class="btn-primary"
                  v-if="
                    aeroporto.ticket_volta !== null &&
                    aeroporto.ticket_volta !== ''
                  "
                  :href="aeroporto.ticket_volta"
                  >Baixar e-ticket</a
                >
              </div>
            </div>
            <div v-else>
              <div class="help" v-if="showAereo">
                <h3>Suas informações de aéreo estarão disponíveis em breve.</h3>
              </div>
            </div>
          </div>
          <div v-else-if="activeTabIndex === 1">
            <div class="hospedagem-box" v-if="!isObjectEmpty(hospedagem)">
              <h6>Dados de hospedagem</h6>
              <h3>{{ hospedagem.hotel }}</h3>
              <div>
                <h5>Check In:</h5>
                <p>{{ hospedagem.check_in }}</p>
              </div>
              <div>
                <h5>Check Out:</h5>
                <p>{{ hospedagem.check_out }}</p>
              </div>
            </div>
            <div v-else>
              <div class="help" v-if="showHosp">
                <h3>Suas informações de hospedagem estarão disponíveis em breve.</h3>
              </div>
            </div>
          </div>
          <div v-else-if="activeTabIndex === 2">
            <div class="transfer-box" v-if="!isObjectEmpty(transfer)">
              <h6>Transfer</h6>
              <div>
                <h3 class="pb-5">Ida</h3>
                <h5>{{ transfer.origem_ida }} - {{ transfer.destino_ida }}</h5>
              </div>
              <div class="pb-30">
                <h5>Ponto de encontro:</h5>
                <p class="pb-10">{{ transfer.ponto_encontro_ida }}</p>
                <h5>Horário de embarque:</h5>
                <p>{{ transfer.data_embarque_ida }}</p>
              </div>
              <div>
                <h3 class="pb-15">Volta</h3>
                <h5>
                  {{ transfer.origem_volta }} - {{ transfer.destino_volta }}
                </h5>
              </div>
              <div>
                <h5>Ponto de encontro:</h5>
                <p class="pb-10">{{ transfer.ponto_encontro_volta }}</p>
                <h5>Horário de embarque:</h5>
                <p>{{ transfer.data_embarque_volta }}</p>
              </div>
            </div>
            <div v-else>
              <div class="help" v-if="showTransf">
                <h3>Suas informações de transfer estarão disponíveis em breve.</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getLogistics } from "@/services/api";

export default {
  name: "LogisticPage",
  props: {
    headerHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dates: ["Aéreo", "Hotel", "Transfer"],
      activeTabIndex: 0,
      aeroporto: {},
      hospedagem: {},
      transfer: {},
      showAereo: false,
      showHosp: false,
      showTransf: false,
    };
  },
  async created() {
    await this.fetchLogistics();
  },
  methods: {
    selectTab(index) {
      this.activeTabIndex = index;
    },
    async fetchLogistics() {
      const logisticsData = await getLogistics(localStorage.getItem("cpf"));
      if (logisticsData) {
        this.aeroporto = logisticsData.aeroporto;
        this.hospedagem = logisticsData.hospedagem;
        this.transfer = logisticsData.transfer;
      }
      this.showAereo = this.isObjectEmpty(this.aeroporto);
      this.showHosp = this.isObjectEmpty(this.hospedagem);
      this.showTransf = this.isObjectEmpty(this.transfer);
    },
    isObjectEmpty(obj) {
      return Object.keys(obj).length === 0;
    },
  },
};
</script>

<style scoped src="@/assets/css/logistics.css"></style>
