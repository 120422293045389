<template>
  <div class="container" :style="{ paddingTop: `${headerHeight}px` }">
    <div v-if="galleryItems.length === 0" class="no-items-message">
      <h1 v-if="!loading">Não há itens na galeria disponíveis no momento.</h1>
    </div>
    <div
      class="gallery-box"
      v-for="galleryItem in galleryItems"
      :key="galleryItem.id"
    >
      <template v-if="isVideo(galleryItems.media)">
        <video controls>
          <source :src="galleryItem.media" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </template>
      <template v-else>
        <img :src="galleryItem.media" :alt="galleryItem.content" />
      </template>
      <div class="content">
        <p>{{ galleryItem.content }}</p>
        <div class="date">
          <small>Publicado em</small>
          <p>{{ galleryItem.data }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGallery } from "@/services/api";

export default {
  name: "GalleryPage",
  props: {
    headerHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      galleryItems: [],
    };
  },
  async created() {
    const response = await getGallery();
    console.log(response);
    this.galleryItems = response;
    if (response.length > 0) {
      this.loading = true;
    } else {
      this.loading = false;
    }
  },
  methods: {
    isVideo(url) {
      return /\.(mp4|ogg|webm)$/.test(url);
    },
  },
};
</script>

<style scoped src="@/assets/css/gallery.css"></style>
