<template>
  <header ref="headerRef">
    <div class="container">
      <div class="row pl-0">
        <img class="logo" src="@/assets/images/logo.png" alt="Logo" />
        <div class="account">
          <template v-if="!userData || !userData.image">
            <svg-icon
              type="mdi"
              :path="mdiAccountCircleOutline"
              :size="40"
              color="#F8BD8E"
            ></svg-icon>
          </template>
          <template v-else>
            <img class="user-image" :src="userData.image" alt="User Profile" />
          </template>
        </div>
      </div>
      <div class="row a-end">
        <p class="welcome-text">Olá, {{ userData ? userData.nome : "" }}</p>
        <div class="icons">
          <div class="icons-box" @click="onIconClick('Booking')">
            <svg-icon
              type="mdi"
              :path="mdiCalendarCheckOutline"
              :size="30"
              color="#F8BD8E"
            ></svg-icon>
            <p>Reservas</p>
          </div>
          <div class="icons-box">
            <a href="mailto:contato@convencaocopaenergia.com.br">
              <svg-icon
                type="mdi"
                :path="mdiHelpCircleOutline"
                :size="30"
                color="#F8BD8E"
              ></svg-icon>
              <p>Ajuda</p>
            </a>
          </div>
          <div class="icons-box" @click="onIconClick('QrCode')">
            <svg-icon
              type="mdi"
              :path="mdiQrcode"
              :size="30"
              color="#F8BD8E"
            ></svg-icon>
            <p>Ingresso</p>
          </div>
        </div>
      </div>
    </div>
    <div class="container container-icons">
      <div class="row">
        <div class="icons-box" @click="onIconClick('News')">
          <svg-icon
            type="mdi"
            :path="mdiNewspaperVariantOutline"
            :size="30"
            color="#0a2923"
          ></svg-icon>
          <p>Novidades</p>
        </div>
        <div class="icons-box" @click="onIconClick('Schedule')">
          <svg-icon
            type="mdi"
            :path="mdiCalendarMonth"
            :size="30"
            color="#0a2923"
          ></svg-icon>
          <p>Agenda</p>
        </div>
        <div class="icons-box" @click="onIconClick('Logistic')">
          <svg-icon
            type="mdi"
            :path="mdiPlaneCar"
            :size="30"
            color="#0a2923"
          ></svg-icon>
          <p>Logística</p>
        </div>
        <div class="icons-box" @click="onIconClick('Interactivity')">
          <svg-icon
            type="mdi"
            :path="mdiGestureTapButton"
            :size="30"
            color="#0a2923"
          ></svg-icon>
          <p>Interatividade</p>
        </div>
        <div class="icons-box" @click="onIconClick('Gallery')">
          <svg-icon
            type="mdi"
            :path="mdiImageOutline"
            :size="30"
            color="#0a2923"
          ></svg-icon>
          <p>Galeria</p>
        </div>
      </div>
      <div class="dash"></div>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import SvgIcon from "@jamescoyle/vue-icon";
import {
  mdiAccountCircleOutline,
  mdiCalendarCheckOutline,
  mdiHelpCircleOutline,
  mdiQrcode,
  mdiNewspaperVariantOutline,
  mdiCalendarMonth,
  mdiPlaneCar,
  mdiGestureTapButton,
  mdiImageOutline,
} from "@mdi/js";

export default {
  name: "HeaderHomePage",
  components: {
    SvgIcon,
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
    }),
  },
  mounted() {
    this.headerHeight = this.$refs.headerRef.clientHeight;
  },
  data() {
    return {
      mdiAccountCircleOutline: mdiAccountCircleOutline,
      mdiCalendarCheckOutline: mdiCalendarCheckOutline,
      mdiHelpCircleOutline: mdiHelpCircleOutline,
      mdiQrcode: mdiQrcode,
      mdiNewspaperVariantOutline: mdiNewspaperVariantOutline,
      mdiCalendarMonth: mdiCalendarMonth,
      mdiPlaneCar: mdiPlaneCar,
      mdiGestureTapButton: mdiGestureTapButton,
      mdiImageOutline: mdiImageOutline,
      headerHeight: 0,
    };
  },
  methods: {
    onIconClick(iconName) {
      this.$emit("iconClick", iconName + "Page"); // Emitting the icon name with 'Page' appended
    },
  },
};
</script>

<style scoped src="@/assets/css/header.css"></style>
