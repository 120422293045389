<template>
  <div class="register-container">
    <HeaderComponent></HeaderComponent>
    <main>
      <form @submit.prevent="submitForm">
        <input
          type="text"
          v-model="cpf"
          placeholder="CPF"
          minlength="14"
          maxlength="14"
          @input="cpfMask"
          required
        />
        <input
          v-if="showPasswordInput"
          type="password"
          v-model="senha"
          placeholder="Senha"
          required
        />
        <div class="need-help" v-if="showPasswordInput">
          <a href="#" @click="forgetPassword">Esqueci minha senha</a>
        </div>
        <p class="error" v-if="passwordIncorrect">Senha incorreta.</p>
        <button class="btn-primary" type="submit" :disabled="isButtonClicked">
          Avançar
        </button>
      </form>
      <div class="need-help">
        <a href="mailto:contato@convencaocopaenergia.com.br"
          >Precisa de ajuda?</a
        >
      </div>
    </main>
    <FooterComponent></FooterComponent>
    <ModalCpfError
      :show="showModal"
      :message="modalMessage"
      @close="showModal = false"
    />
    <ResetPasswordModal
      :show="showResetPasswordModal"
      @close="showResetPasswordModal = false"
    />
  </div>
</template>

<script>
import { checkCpf, login, forgetPassword } from "@/services/api";
import { mapActions } from "vuex";
import ModalCpfError from "@/components/Login/ModalCpfError.vue";
import HeaderComponent from "../Header/HeaderComponent.vue";
import FooterComponent from "@/components/Footer/FooterComponent.vue";
import ResetPasswordModal from "@/components/Login/ResetPasswordModal.vue";

export default {
  components: {
    ModalCpfError,
    HeaderComponent,
    FooterComponent,
    ResetPasswordModal,
  },
  name: "ValidateCpf",
  data() {
    return {
      cpf: "",
      senha: "",
      showPasswordInput: false,
      showModal: false,
      modalMessage: "",
      isButtonClicked: false,
      passwordIncorrect: false,
      showResetPasswordModal: false,
    };
  },
  methods: {
    ...mapActions(["updateUserData"]),
    async submitForm() {
      try {
        const cpfNumerico = this.cpf.replace(/\D/g, "");
        if (this.senha.length > 0) {
          try {
            if (this.senha) {
              await this.LoginForm(cpfNumerico, this.senha);
            } else {
              console.log("Por favor, preencha a senha.");
            }
          } catch (error) {
            console.error("Erro ao enviar CPF e senha:", error);
          }
          return;
        }

        const response = await checkCpf(cpfNumerico);

        if (!response) {
          this.showModal = true;
          return;
        }

        if (response) {
          this.updateUserData(response);
          if (response.cadastro_plataforma) {
            this.showPasswordInput = true;
          } else {
            this.$emit("next");
          }
        } else {
          console.error("CPF não encontrado na base de dados.");
        }
      } catch (error) {
        console.error("Erro ao validar CPF:", error);
      }
    },
    async LoginForm(cpf, senha) {
      const response = await login(cpf, senha);
      if (response) {
        this.passwordIncorrect = false;
        this.updateUserData(response);
        localStorage.setItem("token", response.token);
        localStorage.setItem("id", response.id);
        localStorage.setItem("cpf", response.cpf);
        localStorage.setItem("permission", response.permission);
        if (response.permission === 2) {
          this.$router.push("/home");
          this.$emit("login");
        } else {
          this.$router.push("/admin");
          this.$emit("admin");
        }
      } else {
        this.passwordIncorrect = true;
      }
    },
    cpfMask() {
      // Remove caracteres não numéricos do CPF
      this.cpf = this.cpf.replace(/\D/g, "");

      // Formata o CPF
      if (this.cpf.length > 3 && this.cpf.length < 7) {
        this.cpf = this.cpf.replace(/^(\d{3})(\d)/, "$1.$2");
      } else if (this.cpf.length > 6 && this.cpf.length < 10) {
        this.cpf = this.cpf.replace(/^(\d{3})(\d{3})(\d)/, "$1.$2.$3");
      } else if (this.cpf.length > 9) {
        this.cpf = this.cpf.replace(
          /^(\d{3})(\d{3})(\d{3})(\d)/,
          "$1.$2.$3-$4"
        );
      }
    },
    async forgetPassword() {
      try {
        // Call the forgetPassword API function
        this.showResetPasswordModal = true;
        const cpfNumerico = this.cpf.replace(/\D/g, "");
        await forgetPassword(cpfNumerico);
        // Optionally, show a message to the user indicating that the forget password request was sent
      } catch (error) {
        console.error("Error sending forget password request:", error);
        // Optionally, show an error message to the user
      }
    },
  },
};
</script>

<style scoped src="@/assets/css/register.css"></style>
<style>
body {
  height: 100vh;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

a {
  color: #fff;
  font-family: "GT America Regular", sans-serif;
}
</style>
