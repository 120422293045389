<template>
  <div class="container" :style="{ paddingTop: `${headerHeight}px` }">
    <div class="news-box" v-for="newsItem in newsItems" :key="newsItem.id">
      <template v-if="isVideo(newsItem.media)">
        <video controls>
          <source :src="newsItem.media" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </template>
      <template v-else>
        <img :src="newsItem.media" :alt="newsItem.content" />
      </template>
      <p v-if="!newsItem.expanded && newsItem.content.length > 100">
        {{ newsItem.content.slice(0, 100) }}...
        <button @click="toggleExpand(newsItem)">Ver mais</button>
      </p>
      <p v-else>
        {{ newsItem.content }}
        <button v-if="newsItem.expanded" @click="toggleExpand(newsItem)">
          Ver menos
        </button>
      </p>
    </div>
  </div>
</template>

<script>
import { getNews } from "@/services/api";

export default {
  name: "NewsPage",
  props: {
    headerHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      newsItems: [],
    };
  },
  async created() {
    const response = await getNews();
    this.newsItems = response.map((item) => ({
      ...item,
      expanded: false,
    }));
  },
  methods: {
    isVideo(url) {
      return /\.(mp4|ogg|webm)$/.test(url);
    },
    toggleExpand(newsItem) {
      newsItem.expanded = !newsItem.expanded;
    },
  },
};
</script>

<style scoped src="@/assets/css/news.css"></style>
