<template>
  <div class="register-container">
    <HeaderComponent></HeaderComponent>
    <main>
      <h3>Pronto! Seu cadastro foi concluído.</h3>
      <p>
        Este é o seu ingresso para o evento, você receberá ele por e-mail e
        também estará disponível na plataforma.
      </p>
      <p>
        Sua senha de acesso à plataforma são os 4 últimos dígitos do seu
        telefone cadastrado.
      </p>
      <img style="width: 50%" :src="registerRes.qrCode" alt="qrcode" />
      <button class="btn-primary mt-10" @click="conclude">Concluir</button>
    </main>
    <FooterComponent></FooterComponent>
  </div>
</template>

<script>
import HeaderComponent from "@/components/Header/HeaderComponent.vue";
import FooterComponent from "@/components/Footer/FooterComponent.vue";
import { mapState } from "vuex";

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
  name: "RegisterComplete",
  computed: {
    ...mapState({
      registerRes: (state) => state.registerRes,
    }),
  },
  mounted() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  },
  methods: {
    conclude() {
      localStorage.setItem("token", this.registerRes.token);
      localStorage.setItem("id", this.registerRes.id);
      this.$emit("next");
    },
  },
};
</script>

<style scoped src="@/assets/css/register.css"></style>

<style scoped>
h3 {
  color: #fff;
  margin: 0;
}
main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.register-container {
  width: 100%;
}

button {
  margin-top: 25px;
}

main {
  width: 80%;
}

img {
  margin-top: 25px;
}
</style>
