<template>
  <div>
    <component
      :is="currentStepComponent"
      @next="nextStep"
      @prev="prevStep"
      @login="login"
      v-if="this.isMobile"
    />
    <DesktopComponent v-if="!this.isMobile"></DesktopComponent>
  </div>
</template>

<script>
import ValidateCpf from "./ValidateCpf.vue";
import UserRegister from "./UserRegister.vue";
import ImageRegister from "./ImageRegister.vue";
import RegisterComplete from "./RegisterComplete.vue";
import HomePage from "@/components/HomePage/HomePage.vue";
import DesktopComponent from "./DesktopComponent.vue";
import AdminComponent from "../Admin/AdminComponent.vue";
import { mapState } from "vuex";

export default {
  components: {
    ValidateCpf,
    UserRegister,
    ImageRegister,
    RegisterComplete,
    HomePage,
    DesktopComponent,
    AdminComponent
  },
  data() {
    return {
      currentStep: 0,
      isMobile: false,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.userData,
    }),
    currentStepComponent() {
      switch (this.currentStep) {
        case 0:
          return "ValidateCpf";
        case 1:
          return "UserRegister";
        case 2:
          return "ImageRegister";
        case 3:
          return "RegisterComplete";
        default:
          return "ValidateCpf";
      }
    },
  },
  methods: {
    nextStep() {
      if (this.currentStep === 3) {
        this.$router.push("/home");
      } else {
        this.currentStep++;
      }
    },
    prevStep() {
      this.currentStep--;
    },
    login() {
      this.$router.push("/home");
    },
    admin() {
      this.$router.push("/admin");
    },
  },
  mounted() {
    // Detecta se o usuário está em um dispositivo móvel
    this.isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
  },
};
</script>
